"use client";

import Image from "next/image";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
} from "@/components/ui/carousel";
import { WheelGesturesPlugin } from "embla-carousel-wheel-gestures";
import React, { useState } from "react";
import { useDotButton } from "@/hooks/useDotButton";
import CarouselTabs from "./CarouselTabs";
import CarouselDots from "./CarouselDots";
import AutoHeight from "embla-carousel-auto-height";
import Autoplay from "embla-carousel-autoplay";

const CarouselImageBorder = () => {
  return <div className="h-4 w-full rounded-t-[20px] bg-white" />;
};
const CarouselOverlay = () => {
  return (
    <div className="absolute inset-0 z-10 rounded-[18px] bg-gradient-to-b from-transparent via-black/[0.3597] to-black/50" />
  );
};

export default function ProjectsCarousel({ projects }) {
  const [api, setApi] = useState();
  const { selectedIndex, onDotButtonClick } = useDotButton(api);

  return (
    <div className="flex flex-col-reverse gap-6 lg:flex-col lg:gap-12">
      <Carousel
        setApi={setApi}
        opts={{ loop: true }}
        plugins={[
          WheelGesturesPlugin(),
          AutoHeight({ active: true }),
          Autoplay({
            stopOnMouseEnter: true,
            delay: 4000,
            stopOnInteraction: false,
          }),
        ]}
      >
        <CarouselContent className={"items-start transition-[height]"}>
          {projects.map((project) => (
            <CarouselItem
              key={project.image}
              className="flex select-none flex-col items-center gap-6 lg:flex-row"
            >
              <div className="flex-1">
                <div className="relative flex flex-col justify-center overflow-hidden rounded-[20px] border-[0.7px] border-primary bg-white lg:border-2">
                  <CarouselImageBorder />
                  <CarouselOverlay />

                  <div className="relative h-[200px] w-[355px] max-[390px]:h-[133px] max-[390px]:w-[288px] md:h-[300px] md:w-[533px] lg:w-full xl:h-[371px] xl:w-[full]">
                    <Image fill src={project.image} alt={project.title} />
                  </div>
                  <CarouselImageBorder />
                </div>
              </div>

              <div className="flex flex-1 flex-col">
                <h3 className="mb-4 text-[32px] font-bold leading-10 text-[#0F172A]">
                  {project.title}
                </h3>
                <p className="mb-4 text-xl font-normal leading-8">
                  {project.description}
                </p>

                <h4 className="mb-1 text-2xl font-bold leading-7 text-[#0F172A]">
                  Key Features:
                </h4>
                <ol className="list-inside list-disc pl-1">
                  {project.features.map((feature, index) => (
                    <li className="text-xl font-normal leading-9" key={index}>
                      {feature}
                    </li>
                  ))}
                </ol>
              </div>
            </CarouselItem>
          ))}
        </CarouselContent>
      </Carousel>

      <div className="flex flex-col items-center gap-12">
        <CarouselDots
          className="hidden lg:flex"
          onDotButtonClick={onDotButtonClick}
          projects={projects}
          selectedIndex={selectedIndex}
        />

        <CarouselTabs
          onDotButtonClick={onDotButtonClick}
          projects={projects}
          selectedIndex={selectedIndex}
        />
      </div>
    </div>
  );
}
