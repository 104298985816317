"use client";

import { Parallax } from "react-scroll-parallax";

export default function Parallaxed({ children, ...props }) {
  return (
    <Parallax className="" {...props}>
      {children}
    </Parallax>
  );
}
