"use client";

import Marquee from "react-fast-marquee";
import Image from "next/image";
import { cn } from "@/lib/utils";

const companies = [
  {
    src: "/logos/clients/fastex.png",
    alt: "Fastex",
    w: 113,
    h: 29,
  },
  {
    src: "/logos/clients/evocabank.png",
    alt: "Evocabank",
    w: 115,
    h: 12,
  },
  {
    src: "/logos/clients/sproot.png",
    alt: "Sproot",
    w: 99,
    h: 27,
    className: "invert",
  },
  {
    src: "/logos/clients/insurance.png",
    alt: "Peoples's Trust",
    w: 99,
    h: 26,
    className: "invert",
  },
  {
    src: "/logos/clients/zenit.svg",
    alt: "Zenit",
    w: 99,
    h: 26,
    className: "invert",
  },
];

export default function CompaniesMarquee({ speed = 50, ...props }) {
  return (
    <Marquee autoFill={true} speed={speed} {...props}>
      {companies.map(({ alt, src, w, h, className }) => (
        <Image
          key={alt}
          src={src}
          width={w}
          height={h}
          className={cn("mx-3", className)}
          alt={alt}
        />
      ))}
    </Marquee>
  );
}
