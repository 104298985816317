"use client";

import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useToast } from "@/hooks/use-toast";
import { submitForm } from "@/app/actions";
import { useState } from "react";
import { Loader2 } from "lucide-react";

const formSchema = z.object({
  name: z
    .string()
    .min(2)
    .max(50)
    .refine((value) => /^[a-zA-Z\s-]+$/.test(value), {
      message: "Name must only contain letters, spaces, and hyphens",
    }),
  email: z.string().email(),
  phonenumber: z
    .string()
    .optional()
    .refine((value) => value === "" || /^\+?[0-9]{5,20}$/.test(value), {
      message: "Phone number must be a valid",
    }),
  message: z.string().min(5).max(500),
});

export default function ContactUsForm() {
  const { toast } = useToast();
  const [loading, setLoading] = useState(false);
  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: "",
      email: "",
      phonenumber: "",
      message: "",
    },
  });

  async function onSubmit(values) {
    setLoading(true);

    const { success, message } = await submitForm(values);

    if (success) {
      toast({
        duration: 5000,
        description: message,
      });
    } else {
      toast({
        duration: 5000,
        description: message,
        variant: "destructive",
      });
    }

    form.reset();

    setLoading(false);
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col gap-8 text-[#0F172A] lg:gap-12"
      >
        <div className="flex flex-col gap-8 lg:flex-row">
          <FormField
            control={form.control}
            name="name"
            type="text"
            render={({ field }) => (
              <FormItem className="flex-1 space-y-0">
                <FormLabel className="pl-[10px] text-xl font-normal leading-9">
                  Your Name *
                </FormLabel>
                <FormControl>
                  <Input variant="borderless" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem className="flex-1 space-y-0">
                <FormLabel className="pl-[10px] text-xl font-normal leading-9">
                  Email Address *
                </FormLabel>
                <FormControl>
                  <Input variant="borderless" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="phonenumber"
            render={({ field }) => (
              <FormItem className="flex-1 space-y-0">
                <FormLabel className="pl-[10px] text-xl font-normal leading-9">
                  Phone Number (optional)
                </FormLabel>
                <FormControl>
                  <Input variant="borderless" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <FormField
          control={form.control}
          name="message"
          render={({ field }) => (
            <FormItem className="flex-1 space-y-0">
              <FormLabel className="pl-[10px] text-xl font-normal leading-9">
                Message *
              </FormLabel>
              <FormControl>
                <Textarea variant="borderless" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <Button
          disabled={loading}
          className="w-full self-start rounded-lg px-2 text-2xl sm:w-auto sm:px-8"
          type="submit"
          size="xlg"
        >
          {loading && <Loader2 className="mr-2 h-6 w-6 animate-spin" />}
          Leave us a Message
        </Button>
      </form>
    </Form>
  );
}
