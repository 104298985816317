"use client";
import { cn } from "@/lib/utils";
import React, { useState, useEffect } from "react";

const TypewriterEffect = ({
  messages,
  delay = 50,
  pauseBetweenMessages = 1000,
  className,
}) => {
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const [currentText, setCurrentText] = useState("");

  useEffect(() => {
    if (currentMessageIndex >= messages.length) return;
    if (currentText.length < messages[currentMessageIndex].text.length) {
      const timeout = setTimeout(() => {
        setCurrentText(
          messages[currentMessageIndex].text.slice(0, currentText.length + 1),
        );
      }, delay);
      return () => clearTimeout(timeout);
    } else {
      const timeout = setTimeout(() => {
        if (currentMessageIndex < messages.length - 1) {
          setCurrentMessageIndex((prevIndex) => prevIndex + 1);
          setCurrentText("");
        }
      }, pauseBetweenMessages);
      return () => clearTimeout(timeout);
    }
  }, [currentText, currentMessageIndex, messages, delay, pauseBetweenMessages]);

  return (
    <div
      className={cn("flex w-full max-w-sm flex-col gap-2 md:gap-4", className)}
    >
      {messages.map(({ text, className }, index) => (
        <React.Fragment key={index}>
          {index <= currentMessageIndex ? (
            <div
              key={index}
              className={cn(
                "min-h-[36px] rounded-lg px-3 py-2 text-sm shadow-[0px_0px_4px_0px_#00000012,0px_4px_6px_-1px_#0000001A] transition-all duration-200 lg:min-h-[40px] lg:px-4 lg:text-base",
                index === currentMessageIndex && "animate-fade-in",
                index > currentMessageIndex && "text-transparent",
                className,
              )}
            >
              <p className={""}>
                {index === currentMessageIndex ? currentText : text}
              </p>
            </div>
          ) : null}
        </React.Fragment>
      ))}
    </div>
  );
};

export default TypewriterEffect;
