import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/home/CompaniesMarquee.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/home/ContactUs/ContactUsForm.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/home/Projects/ProjectsCarousel.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/home/TypeWriterEffect.jsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/shared/Parallaxed.jsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/vercel/path0/node_modules/react-fast-marquee/dist/index.js");
