import { Button } from "@/components/ui/button";

export default function CarouselTabs({
  projects,
  selectedIndex,
  onDotButtonClick,
}) {
  return (
    <div className="flex flex-wrap justify-center gap-[14px]">
      {projects.map((project, index) => (
        <Button
          className={`h-auto rounded-3xl px-8 py-[15px] text-xl font-normal ${selectedIndex === index ? "bg-primary text-white" : "bg-[#F2F4F7] text-black hover:bg-accent/30"}`}
          key={`tab-${index}`}
          onClick={() => onDotButtonClick(index)}
        >
          {project.tab}
        </Button>
      ))}
    </div>
  );
}
