import { cn } from "@/lib/utils";

export default function CarouselDots({
  projects,
  selectedIndex,
  onDotButtonClick,
  className,
}) {
  return (
    <div className={cn("relative gap-[11px]", className)}>
      {projects.map((_, index) => (
        <div
          key={`dot-${index}`}
          onClick={() => onDotButtonClick(index)}
          className="relative h-[15px] w-[15px] cursor-pointer"
        >
          <div className="absolute inset-0 rounded-full bg-[#E4E7EC]" />
        </div>
      ))}
      <div
        className="absolute rounded-full bg-primary transition-all duration-300 ease-in-out"
        style={{
          width: "15px",
          height: "15px",
          left: `${selectedIndex * 26}px`,
        }}
      />
    </div>
  );
}
